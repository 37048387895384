import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Button from "./button"

const styles = {
  slide: {
    position: "relative",
    color: "#fff",
  },
  slide1: {
    overflow: "hidden",
  },
  slide2: {
    overflow: "hidden",
  },
  slide3: {
    overflow: "hidden",
  },
  slide4: {
    overflow: "hidden",
  },
  slide5: {
    overflow: "hidden",
  },
}

const Wrapper = styled.div`
  position: relative;
  margin: 0;
`

const Margins = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    position: relative;
    margin: 32px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    filter: brightness(0.8);
  }
`

const SlideText = styled.div`
  position: relative;
  margin-top: 4%;
  max-width: 1060px;
  text-align: center;
  color: white;
  z-index: 5;

  h1 {
    margin: auto;
    font-size: 3.5rem;
    font-weight: 900;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);
  }

  p {
    margin: 38px auto;
    max-width: ${props => props.theme.breakpoints.smallest};
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.6rem;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  }

  @media only screen and (max-width: 1366px) {
    transform: scale(0.8);
  }

  @media only screen and (max-width: 960px) {
    transform: scale(0.6);
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    transform: scale(1);

    h1,
    p {
      color: black;
      text-shadow: none;
    }

    h1 {
      font-size: 2.4rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: ${props => props.theme.breakpoints.smallest};

  @media only screen and (max-width: 440px) {
    flex-direction: column;
    align-items: center;

    button {
      margin: 4px;
    }
  }
`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Carousel = () => (
  <StaticQuery
    query={graphql`
      {
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SWT_Weiß.png|Smart_Style_Anthrazit_02|g60 sarleinsbach trend szene1b_ohne nummernschild|eurotrend_weiß reihenhaus1|OSD_Scene_Eurostyle D338_7004_DSC8898/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Wrapper>
        {/* <Margins>
          <SlideText>
            <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum sit amet ipsum dolor sit tempus.
            </p>
            <div>
              <Button>Request a Quote</Button>
              <Button>Call us Now</Button>
            </div>
          </SlideText>
        </Margins> */}

        <AutoPlaySwipeableViews interval="3000">
          <div style={Object.assign({}, styles.slide, styles.slide1)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[0].node.fluid} />
            </ImageWrapper>
            {/* <Margins>
              <SlideText>
                <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum sit amet ipsum dolor sit tempus.
                </p>
                <div>
                  <Button>Request a Quote</Button>
              <Button>Call us Now</Button>
                </div>
              </SlideText>
            </Margins> */}
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide2)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[1].node.fluid} />
            </ImageWrapper>
            {/* <Margins>
              <SlideText>
                <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum sit amet ipsum dolor sit tempus.
                </p>
                <div>
                  <Button>Request a Quote</Button>
              <Button>Call us Now</Button>
                </div>
              </SlideText>
            </Margins> */}
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide3)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[2].node.fluid} />
            </ImageWrapper>
            {/* <Margins>
              <SlideText>
                <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum sit amet ipsum dolor sit tempus.
                </p>
                <div>
                  <Button>Request a Quote</Button>
              <Button>Call us Now</Button>
                </div>
              </SlideText>
            </Margins> */}
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide4)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[3].node.fluid} />
            </ImageWrapper>
            {/* <Margins>
              <SlideText>
                <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum sit amet ipsum dolor sit tempus.
                </p>
                <div>
                  <Button>Request a Quote</Button>
              <Button>Call us Now</Button>
                </div>
              </SlideText>
            </Margins> */}
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide5)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[4].node.fluid} />
            </ImageWrapper>
            {/* <Margins>
              <SlideText>
                <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum sit amet ipsum dolor sit tempus.
                </p>
                <div>
                  <Button>Request a Quote</Button>
              <Button>Call us Now</Button>
                </div>
              </SlideText>
            </Margins> */}
          </div>
        </AutoPlaySwipeableViews>

        <Margins>
          <SlideText>
            <h1>Lorem ipsum dolor sit amet, consectetur adipscing elit.</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum sit amet ipsum dolor sit tempus.
            </p>
            <Flex>
              {/* <Button>Request a Quote</Button> */}
              <Button>Call us Now</Button>
            </Flex>
          </SlideText>
        </Margins>
      </Wrapper>
    )}
  />
)

Carousel.propTypes = {
  images: PropTypes.string,
}

Carousel.defaultProps = {
  images: ``,
}

export default Carousel
