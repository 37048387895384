import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Carousel from "../components/home-carousel"
import Welcome from "../components/home-welcome"
import Products from "../components/home-products"
import Services from "../components/home-services"
import LogoSpan from "../components/home-logos"
import PartnerSlider  from "../components/partner-slider"

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }
`

const Home = () => (
      <Layout>
        {/* <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} /> */}
        <Carousel />
        <Welcome />
        <Margins>
          <Products />
          <Services />
        </Margins>
        <PartnerSlider />
        <LogoSpan />
      </Layout>
)

export default Home
