import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import MobileStepper from "@material-ui/core/MobileStepper"
import styled from "styled-components"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import logo1 from "../images/logo-normstahl.jpg"

const Testimonial = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  background-color: ${({ theme }) => theme.palette.mainBlue};
  padding: 30px;
  color: white;
`

const StyledMobileStepper = styled(MobileStepper)`
  background: ${({ theme }) => theme.palette.mainBlue};

  .MuiMobileStepper-dot {
    background-color: ${({ theme }) => theme.palette.lightText};
  }
  .MuiMobileStepper-dotActive {
    background-color: #3f51b5;
    padding: 0;
  }
  .MuiSvgIcon-root {
    fill: white;
    width: 2em;
    height: 2em;
  }
`

const StyledBox = styled(Box)`
  margin: 64px 0;
`

const TextWrapper = styled.div`
  padding-left: 20px;
`

const Logo = styled.a`
  padding: 10px;
  background: #fff;
  margin: 20px;

  :hover {
    transform: scale(1.06);
  }
`

const ImageResizer = styled.img`
  margin: 5px 0;
  width: 205px;
  padding-bottom: calc(332 / 125) %;
`

var testimonials = [
  {
    name: "Normstahl",
    text:
      "Die Firma Schneider Tore arbeitet seit Jahren mit der Firma Normstahl zusammen und verbaut Garagentore, Garagentüren und Torantriebe. Ein Garagentor von Normstahl ist Qualität.",
  },
]

function PartnerSlider(props) {
  const [activeStep, setActiveStep] = React.useState(0)

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  function handleStepChange(step) {
    setActiveStep(step)
  }
  return (
    <StyledBox>
      <SwipeableViews
        enableMouseEvents
        index={activeStep}
        style={{
          overflow: "hidden",
        }}
        onChangeIndex={handleStepChange}
      >
        {testimonials.map((step, index) => (
            <Testimonial key={step.id} testimonial={step}>
            <Logo
              href="https://normstahl.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageResizer src={logo1} alt="Icon" />
            </Logo>
            <TextWrapper>
            <h2>{step.name}</h2>
            <p>{step.text}</p>
            </TextWrapper>
          </Testimonial>
        ))}
      </SwipeableViews>
      <StyledMobileStepper
        activeStep={activeStep}
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </Button>
        }
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={testimonials && activeStep === testimonials.length - 1}
          >
            <KeyboardArrowRight />
          </Button>
        }
        position="static"
        steps={testimonials && testimonials.length}
        variant="dots"
      />
    </StyledBox>
  )
}

export default PartnerSlider
