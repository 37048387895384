import React from "react"
import styled from "styled-components"
 

const Span = styled.div`
  background: ${({ theme }) => theme.palette.spanCol};
  position: relative;
  padding: 48px;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 32px;
  }

  hr {
    margin: auto auto 32px;
    width: 14%;
    border: 1px solid black;
  }

  p {
    margin: 16px auto;
    max-width: 840px;
    font-weight: 500 !important;
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
`
const Welcome = () => (
  <Span>
    <h2>Welcome to ATZ Bauelemente</h2>
    

    <hr />

    <p>
      Sed velit nulla, finibus in maximus nunc efficitur at, elementum id justo.
      Proin, nibh at blandit maximus ipsum, eu maximus nunc augue at elit. Morbi
      habitasse platea dictumst habitasse platea dictumst condimentum enim quis
      erat molestie, nec sollicitudin erat laoreet. In hac habitasse platea
      dictumst.
    </p>

    <p>
      Mauris urna maximus ipsum, eu maximus nunc augue at elit. Morbi
      condimentum enim quis erat molestie. Praesent tincidunt tortor vel quam
      sagittis, non fermentum mauris mattis.
    </p>
  </Span>
)

export default Welcome
