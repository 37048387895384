import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Button from "./button"

const Span = styled.div`
  margin: auto;
  text-align: center;

  h2 {
    margin: 48px auto;
    font-size: 2.2rem;
    font-weight: 700;
  }

  button {
    margin: 48px auto;
    padding: 8px;
    width: 150px;
  }
`

const Flex = styled.div`
  display: flex;
  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 18px;

  p {
    font-size: 0.8rem;
    margin: 12px 0;
    font-weight: 700 !important;
    text-align: left;
  }
`

const Welcome = () => (
  <StaticQuery
    query={graphql`
      {
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SWT_Weiß.png|Smart_Style_Anthrazit_02|g60 sarleinsbach trend szene1b_ohne nummernschild|eurotrend_weiß reihenhaus1|OSD_Scene_Eurostyle D338_7004_DSC8898/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 400, maxHeight: 400) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Span>
        <h2>Our Products</h2>
        <Flex>
          <ImageWrapper>
            <Img
              fluid={props.allImageSharp.edges[0].node.fluid}
              alt="Some of our world-famous doors"
            />
            <p>Industrial Doors</p>
          </ImageWrapper>

          <ImageWrapper>
            <Img
              fluid={props.allImageSharp.edges[1].node.fluid}
              alt="Some of our world-famous doors"
            />
            <p>Commercial Doors</p>
          </ImageWrapper>

          <ImageWrapper>
            <Img
              fluid={props.allImageSharp.edges[2].node.fluid}
              alt="Some of our world-famous doors"
            />
            <p>Garage Doors</p>
          </ImageWrapper>

          <ImageWrapper>
            <Img
              fluid={props.allImageSharp.edges[3].node.fluid}
              alt="Some of our world-famous doors"
            />
            <p>Sliding Doors</p>
          </ImageWrapper>
        </Flex>

        <Button>See More</Button>
      </Span>
    )}
  />
)

export default Welcome
