import React from "react"
import styled from "styled-components"

const Span = styled.div`
  margin: 64px 64px 96px;

  h2 {
    margin: 48px auto;
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -48px;

  div {
    flex: 1;
    margin: 48px;
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
  }
`

const Welcome = () => (
  <Span>
    <h2>Our Services</h2>
    <Flex>
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id
        ultricies mauris. Nam scelerisque magna ut ex rutrum ultrices. Vivamus
        lacinia vehicula fermentum. Donec ac nisi dictum, commodo orci non,
        semper tellus scelerisque magna ut ex rutrum. Aenean porta nisi id metus
        suscipit venenatis.
      </div>
      <div>
        Nam suscipit, neque a consequat ornare, est ligula accumsan ipsum, quis
        facilisis metus sapien sed libero. Quisque venenatis non enim non
        fringilla. Integer ante tempor, sed feugiat sem facilisis. Suspendisse
        nisi lacus, ullamcorper pellentesque velit. Phasellus dapibus massa nec
        malesuada volutpat. Sed varius rutrum commodo.
      </div>
    </Flex>
  </Span>
)

export default Welcome
