import React from "react"
import styled from "styled-components"

const Button = styled.button`
  background: ${({ theme }) => theme.palette.navCol};
  padding: 18px 0;
  width: 180px;
  border: none;
  border-radius: 2px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: text-shadow 300ms, transform 150ms;

  :hover {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.7);
    transform: scale(1.02);
  }
`

export default ({ children }) => <Button>{children}</Button>
